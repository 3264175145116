import { atom, selector } from 'recoil';

export const new_series = atom({
    key: 'new_series',
    default: {
        index: '',
        title: '',
        courses: []
    },
});

export const add_course_selector = selector({
    key: 'add_course_selector',
    get: ({ get }) => get(new_series),
    set: ({ set, get }, newValue) => {
        const atom = get(new_series)
        const courses = [...atom.courses, {
            title: '', book: {
                video: '', quiestions: []
            }
        }]
        const state = { ...atom, courses }


        console.log(state)
        set(new_series, state)
    },
});
/*
{
    index: 11,
    title: '1231421241241'
    courses: [

    ]
}

*/