import { getRandomInt } from '../utils/utils'
import { series_selector, series_state } from '../atoms/series/series'
import { timers_atom } from '../atoms/timers/timers'
import { getRecoil, setRecoil } from 'recoil-nexus'
import { new_series } from '../atoms/series/new_series'
let WEBSOCKET_ADDRESS = 'wss://dcl-library-backend-api.dappcraft.io'
const WEBSOCKET_ADDRESS_LOCAL = 'ws://localhost:5010'
import moment from "moment";
import * as momentTimezone from "moment-timezone";
moment.tz.setDefault("America/Chicago");
let targetElement = undefined

function getElement() {
    if (!targetElement) {
        targetElement = document.createElement('div')
    }

    return targetElement
}

let interval = undefined
function query_params_fromatter(query_params) {
    let query_params_result = ''
    if (query_params) {
        query_params_result += '?'
        let keys = Object.keys(query_params)
        keys.forEach((value, index) => {
            let result = `${value}=${query_params[value]}`;
            if (index < (keys.length - 1)) {
                result += '&'
            }
            query_params_result += result
        })
    }
    return query_params_result
}
console.log(window.document)

class WS {
    realm
    room
    address
    user_id
    ws
    reconnect_delay = 1000
    request_timeout = 10000
    change_realm_delay = 2000
    wait_for_connect_delay = 3000
    heartbeat = 4000
    ws_alive = false
    opened = false
    connecting = false
    changingRealm = false
    queue = []
    query_params = {}
    constructor(address, query_params, reconnect_delay, request_timeout) {
        this.address = address || this.address
        this.query_params = query_params || this.query_params
        this.reconnect_delay = reconnect_delay || this.reconnect_delay
        this.request_timeout = request_timeout || this.request_timeout
    }
    async connect() {
        this.user_id = 'a1f379f3d1a5f1432dc3f0d3cfe5ce484021234b'
        this.realm = '123'
        this.room = ''
        this.query_params = { ...this.query_params, realm: this.realm, room: this.room, user_id: this.user_id }
        this.connecting = true
        let instance = this
        return new Promise((resolve, reject) => {

            // log(this)
            let query_params = query_params_fromatter(instance.query_params)
            instance.ws = new WebSocket(`${instance.address}${query_params}`)
            instance.ws.onopen = () => {
                (async () => {
                    let response = await this.request({ route: 'series/get_series' })
                    if (response && response.payload.success) {
                        setRecoil(series_state, response.payload.series)
                        setRecoil(new_series, (curr_value) => {
                            let keys = getRecoil(series_selector)
                            let new_state = { ...curr_value, index: keys.length + 1 }
                            return new_state
                        })
                    }
                })();
                (async () => {
                    let response = await this.request({ route: 'timers/get_timers' })
                    if (response && response.payload.success) {
                        let { start_time, end_time } = response.payload
                        let start_time_string = ''
                        let end_time_string = ''
                        if (start_time !== null && end_time !== null) {
                            start_time_string = moment.unix(start_time / 1000).format("MM‑DD‑YYYY HH:mm")
                            end_time_string = moment.unix(end_time / 1000).format("MM‑DD‑YYYY HH:mm")
                        }
                        setRecoil(timers_atom, {
                            start_time, end_time, end_time_string, start_time_string
                        })
                    }
                })();
                instance.opened = true
                instance.connecting = false
                if (instance.queue.length > 0) {
                    for (let i = 0; i < instance.queue.length; i++) {
                        if (instance.opened) {
                            instance.send(instance.queue[i])
                            instance.queue = instance.queue.slice(0)
                        }
                    }
                }
                instance.ws_alive = true

                interval = setInterval(() => {
                    if (instance.ws_alive === false) {
                        instance.ws.close()
                    }
                    instance.ws_alive = false
                    instance.ws.send(instance.packMessage({ route: 'system/heartbeat' }))
                }, instance.heartbeat)



                // resolve(instance);
            };
            instance.ws.onerror = (err) => {
                instance.ws_alive = false
                instance.opened = false
                instance.connecting = false
                //not needed because onclose fires every case for error or for pure disconnect
                // instance.reconnect()
                // reject(err);
                console.log('WS ERROR')
            };
            instance.ws.onclose = () => {
                //engine.removeEntity(interval)
                instance.ws_alive = false
                instance.opened = false
                instance.connecting = false
                console.log('WS CLOSE')
                instance.reconnect()
            }
            instance.ws.onmessage = ({ data }) => {
                //here we need verify method
                if (data) {
                    let object = instance.unpackMessage(data)

                    if (object && object.route === 'system/heartbeat') {

                        instance.ws_alive = true
                    }
                    if (object && object.request_id) {
                        const event = new CustomEvent('message_got', { detail: object, bubbles: true });

                        getElement().dispatchEvent(event)
                        // observer_request.notifyObservers(object)
                    }
                    if (object && object.route === 'users/state') {
                        let { payload } = object
                        let { series } = payload
                        // setRecoil(series_state, series)
                        // setRecoil(new_series, (curr_value) => {
                        //     let keys = getRecoil(series_selector)

                        //     let new_state = { ...curr_value, index: keys.length + 1 }
                        //     return new_state
                        // })
                    }
                    // if (object && object.route === 'spawns/set_client_collected_spawns') {

                    //     observer_collected_spawns_on_init.notifyObservers(object)
                    // }
                    // //only for push events from the server by book bench
                    // if (object && object.route === 'book_bench/set_client_inactive_status_book_bench') {
                    //     observer_book_bench_inactivated.notifyObservers(object)
                    // }

                }

            }
        });
    }
    onclose() {

    }
    onerror() {

    }

    reconnect() {
        setTimeout(() => {
            this.ws.close()
            this.connect()
        }, this.reconnect_delay)
    }

    request(object) {
        let request_id_sent = getRandomInt(1000000, 1000000000)
        let req = { ...object, request_id: request_id_sent }
        console.log('request')
        console.log(`WS_STATUS opened: ${this.opened},  connecting: ${this.connecting}, changing_realm: ${this.changingRealm}`)
        let instance = this
        let resolved = false
        return new Promise(function (resolve, reject) {
            instance.send(req)
            getElement().addEventListener('message_got', ((event) => {
                let { detail } = event
                console.log('listener', detail)

                let { request_id } = detail
                if (request_id_sent === request_id) {
                    resolved = true
                    console.log(detail)
                    resolve(detail)
                }
            }))
            // instance.ws.onmessage(({ data }) => {
            //     console.log(data)
            //     let object = instance.unpackMessage(data)
            //     let { request_id } = object
            //     if (request_id_sent === request_id) {
            //         resolved = true
            //         console.log(object)
            //         resolve(object)
            //     }
            // })
            let timeout = setTimeout(() => resolved ? '' : reject('timed out'), instance.request_timeout)

            // observer_request.add((eventData, eventState) => {
            //     resolved = true
            //     resolve(eventData)
            // })
            // let setTimeout = setTimeout(() => {
            //     // events.removeListener(null, RESPONSE)
            //     console.log('reject')
            //     resolved ? '' : reject('timed out')
            // }, instance.request_timeout)
        })
    }
    send(object) {

        let req = object
        if (this.opened) {
            if (this.changingRealm) {
                this.queue.push(req)
                console.log(`You are not connected to server, please wait!`)
                return false
            } else {
                if (object) {
                    this.ws.send(this.packMessage(req))
                    return true
                }
            }
        } else {
            this.queue.push(req)
            console.log(`You are not connected to server, please wait!`)
            return false
        }
    }
    unpackMessage(json) {
        try {
            return JSON.parse(json)
        } catch (error) {
            console.log('error unpackmessage')
            console.log(`unpackMessage error`)
            return undefined
        }
    }
    packMessage(object) {
        try {
            let jsoned = JSON.stringify(object)
            return jsoned
        } catch (error) {
            console.log('error packmessage')
            console.log(`packMessage error`)
            return undefined
        }
    }
}
const ws = new WS(WEBSOCKET_ADDRESS);
(async () => {
    await ws.connect()

})()

export default ws
