import * as React from 'react';
import { useSeries } from "../../hooks/series/series";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { series_state } from "../../atoms/series/series";
import ws from "../../Websockets/Instance";
export default function DeleteSeries(props) {
    let { series_key } = props
    const {
        delete_series
    } = useSeries(series_state);

    const [status, setStatus] = React.useState(0);
    const handle_delete = async () => {
        try {
            setStatus(1)
            let response = await ws.request({ route: 'series/delete_series', payload: { id: series_key } })
            let { payload } = response
            let { success } = payload
            if (success) {
                setStatus(2)
                delete_series(series_key)
            } else {
                setStatus(3)
            }
        } catch (error) {
            console.log(error)
            setStatus(3)
        }
    }
    return (
        <>
            {status === 0 ? <Button onClick={handle_delete}>
                <DeleteIcon />
            </Button> : ''}
            {status === 1 ? <CircularProgress /> : ''}
            {status === 2 ? <b>Succes</b> : ''}
            {status === 3 ? <><Button onClick={handle_delete}>
                <DeleteIcon />
            </Button><b>Error</b></> : ''}
        </>
    );
}
