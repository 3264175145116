import { useRecoilState } from 'recoil';

export function useNewSeries(atom) {
    const [new_series_state, set_new_series_state] = useRecoilState(atom);
    // const add_course = () => add_course_hook();
    const add_course = () => {
        const courses = [...new_series_state.courses, {
            title: '', book: {
                video: '', quiestions: []
            }
        }]
        const state = { ...new_series_state, courses }
        console.log(state)
        set_new_series_state(state)
    }
    const delete_course = (course_index) => {
        const new_courses = new_series_state.courses.filter((value, index) => course_index !== index)
        const state = { ...new_series_state, courses: new_courses }
        set_new_series_state(state)
    }
    const add_question_to_course = (index) => {
        console.log(index)

        let course = { ...new_series_state.courses[index] }
        console.log(course)
        let question = { answers: ['', '', '', ''], correct_answer: 0, quiestion: '' }
        let quiestions = [...course.book.quiestions, question]
        let course_new = { title: course.title, book: { quiestions, video: course.book.video } }
        let courses = Object.values({ ...new_series_state.courses, [index]: course_new })
        const state = { ...new_series_state, courses }
        console.log(state)
        set_new_series_state(state)
    }
    const delete_question_by_course = (course_index, question_index) => {
        let course = new_series_state.courses[course_index]
        let { book } = course
        let { quiestions } = book
        let new_quiestions = quiestions.filter((value, index) => question_index !== index)
        let new_book = { ...book, quiestions: new_quiestions }
        let course_new = { ...course, book: new_book }
        let new_courses = Object.values({ ...new_series_state.courses, [course_index]: course_new })
        const state = { ...new_series_state, courses: new_courses }
        set_new_series_state(state)
    }
    const change_vimeo_link_by_course = (index, value) => {
        let course = { ...new_series_state.courses[index] }
        let course_new = { title: course.title, book: { quiestions: course.book.quiestions, video: value } }
        let courses = Object.values({ ...new_series_state.courses, [index]: course_new })
        const state = { ...new_series_state, courses }
        set_new_series_state(state)
    }
    const change_title_by_course = (index, value) => {
        let course = { ...new_series_state.courses[index] }
        let course_new = { title: value, book: course.book }
        let courses = Object.values({ ...new_series_state.courses, [index]: course_new })
        const state = { ...new_series_state, courses }
        set_new_series_state(state)
    }
    const change_series_title = (value) => {
        let state = { ...new_series_state, title: value }
        set_new_series_state(state)
    }
    const change_series_index = (value) => {
        let state = { ...new_series_state, index: value }
        set_new_series_state(state)
    }
    const change_answer_by_course_by_question = (index_course, index_question, index_answer, value) => {
        let question = new_series_state.courses[index_course].book.quiestions[index_question]
        let book = new_series_state.courses[index_course].book
        let { answers } = question
        let answers_new = Object.values({ ...answers, [index_answer]: value })
        console.log(answers_new)
        let question_new = { ...question, answers: answers_new }
        let quiestions = Object.values({ ...new_series_state.courses[index_course].book.quiestions, [index_question]: question_new })
        let new_book = { ...book, quiestions }
        let course_new = { ...new_series_state.courses[index_course], book: new_book }
        let courses = Object.values({ ...new_series_state.courses, [index_course]: course_new })
        let state = { ...new_series_state, courses }
        console.log(state)
        set_new_series_state(state)
    }
    const change_question_by_course_by_question = (index_course, index_question, value) => {
        //def prev state values
        let course = new_series_state.courses[index_course]
        let { book } = course
        let { quiestions } = book
        let question_item = quiestions[index_question]

        let new_question = { ...question_item, quiestion: value }
        let new_quiestions = Object.values({ ...quiestions, [index_question]: new_question })
        let new_book = { ...book, quiestions: new_quiestions }
        let new_course = { ...course, book: new_book }
        let courses = Object.values({ ...new_series_state.courses, [index_course]: new_course })
        let state = { ...new_series_state, courses }
        console.log(state)
        set_new_series_state(state)
    }

    const change_correct_answer_by_course_by_question = (index_course, index_question, value) => {
        //def prev state values
        let course = new_series_state.courses[index_course]
        let { book } = course
        let { quiestions } = book
        let question_item = quiestions[index_question]

        let new_question = { ...question_item, correct_answer: value }
        let new_quiestions = Object.values({ ...quiestions, [index_question]: new_question })
        let new_book = { ...book, quiestions: new_quiestions }
        let new_course = { ...course, book: new_book }
        let courses = Object.values({ ...new_series_state.courses, [index_course]: new_course })
        let state = { ...new_series_state, courses }
        console.log(state)
        set_new_series_state(state)
    }
    const reset_state_and_add_new_index = (index) => {
        const state = {
            index: index,
            title: '',
            courses: []
        }
        set_new_series_state(state)
    }
    return { new_series_state, reset_state_and_add_new_index, add_course, delete_course, add_question_to_course, delete_question_by_course, change_vimeo_link_by_course, change_title_by_course, change_series_title, change_series_index, change_answer_by_course_by_question, change_question_by_course_by_question, change_correct_answer_by_course_by_question }
}