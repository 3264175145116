

import { atom } from 'recoil';

export const timers_atom = atom({
    key: 'timers',
    default: {
        start_time: null,
        end_time: null,
        end_time_string: '',
        start_time_string: ''
    },
});