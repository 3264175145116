import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import moment from 'moment';
import * as momentTimezone from 'moment-timezone'
import { useTimers } from '../hooks/timers/timers';
import { timers_atom } from '../atoms/timers/timers';
import ws from '../Websockets/Instance';
moment.tz.setDefault("America/Chicago");

export default function Timers() {

    let { timers_state, edit_timers } = useTimers(timers_atom)
    let { start_time_string, end_time_string } = timers_state
    const [status, setStatus] = React.useState(0);

    const handle_save = async () => {
        try {
            let start_time = null
            let end_time = null

            let start_time_tz = moment(start_time_string, "MM‑DD‑YYYY HH:mm")
            start_time = start_time_tz.unix()

            let end_time_tz = moment(end_time_string, "MM‑DD‑YYYY HH:mm")
            end_time = end_time_tz.unix()

            console.log(start_time_tz)
            console.log(end_time_tz)
            console.log(start_time)
            console.log(end_time)

            if (start_time === NaN || end_time === NaN) {
                setStatus(4)
                return;
            }

            setStatus(1)
            let response = await ws.request({ route: 'timers/set_timers', payload: { end_time, start_time } })
            let { payload } = response
            let { success } = payload
            if (success) {
                setStatus(2)
            } else {
                setStatus(3)
            }
        } catch (error) {
            setStatus(3)
            console.log(error)
        }
    }
    return (
        <>
            <h1>Livestream timers<br /></h1>
            <Box component={Paper} sx={{ p: 1 }}>
                <Grid container spacing={0} rowSpacing={0} alignItems="stretch" justifyContent="left" >

                    <Grid item xs={12}>
                        <p>Format <b>MM‑DD‑YYYY HH:MM</b></p>
                        <p>Time zone <b>New-York</b></p>
                    </Grid>
                    <Grid item xs={6}>
                        <p><b>Start Livestream</b></p>
                        <TextField value={start_time_string} onChange={({ target: { value } }) => { edit_timers('start_time_string', value) }} required></TextField>

                    </Grid>
                    <Grid item xs={6}>
                        <p><b>End Livestream</b></p>
                        <TextField value={end_time_string} onChange={({ target: { value } }) => { edit_timers('end_time_string', value) }} required></TextField>

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ paddingTop: 2 }}>
                        {status === 1 ? <CircularProgress /> : ''}

                        {status === 0 ? <Button variant="contained" onClick={handle_save}>
                            Save
                        </Button> : ''}
                        {status === 2 ? <b>Success</b> : ''}
                        {status === 3 ? <><Button variant="contained" onClick={handle_save}>Save</Button><b>Error</b></> : ''}
                        {status === 4 ? <><Button variant="contained" onClick={handle_save}>Save</Button><b>Invalid date</b></> : ''}

                    </Box>
                </Grid>
            </Box>
        </>


    );
}
