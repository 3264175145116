import { Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Layout from './Layout/Layout';
import Series from "./pages/Series";
import CurrentSeries from './pages/CurrentSeries'
import NewSeries from "./pages/NewSeries";
import Timers from "./pages/Timers";
import { useLocation } from 'react-router-dom';

function HomePage() {
  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();

  let query = new URLSearchParams(useLocation().search);
  let error = query.get('error');
  let errorDescription = query.get('error_description');

  if (error) {
    return (
      <div>
        <h2>Error: {error}</h2>
        <p>{errorDescription}</p>

        <button onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
      </div>
    );
  }

  return (
    <div>
      { isAuthenticated ? (
        <div>
          <p>Welcome, {user.name}!</p>
          <button onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
        </div>
      ) : (
        <button onClick={loginWithRedirect}>Log in</button>
      )}
    </div>
  );
}

function App() {
  const { isAuthenticated } = useAuth0();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        { isAuthenticated && (
          <>
            <Route path="series" element={<Series />} />
            <Route path="series/new_series" element={<NewSeries />} />
            <Route path="series/current_series/:series_id" element={<CurrentSeries />} />
            <Route path="timers" element={<Timers />} />
          </>
        )}
      </Route>
    </Routes>
  );
}

export default App;
