import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Button, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Outlet } from "react-router-dom";
import { theme } from './Theme';
import { ThemeProvider } from '@mui/material/styles';
import { Link } from "react-router-dom";



import { NavItem } from './NavItem';
const items = [
    {
        href: '/series',
        title: 'Series'
    },
    {
        href: '/timers',
        title: 'Timers'
    }
];

export default function Layout(props) {
    return (
        <ThemeProvider theme={theme}>

            {/* <Box sx={{ minHeight: '100vh' }}> */}
            <Grid container spacing={0} rowSpacing={0} alignItems="stretch" justifyContent="left" alignContent="stretch" sx={{ minHeight: '100vh' }}>


                <Grid item xs={2} alignContent="stretch" sx={{
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                }}>

                    {/* <Drawer
                    anchor="left"
                    open
                    PaperProps={{
                        sx: {
                            backgroundColor: 'neutral.900',
                            color: '#FFFFFF',
                        }
                    }}
                    variant="permanent"
                > */}

                    <Box sx={{ p: 3 }}>
                        <Link
                            to="/"
                        >
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' } }}
                            >
                                Curation
                            </Typography>
                        </Link>
                    </Box>
                    <Divider
                        sx={{
                            borderColor: '#2D3748',
                            my: 3
                        }}
                    />
                    <Box sx={{}}>
                        {items.map((item) => (
                            <NavItem
                                key={item.title}
                                icon={item.icon}
                                href={item.href}
                                title={item.title}
                            />
                        ))}
                    </Box>

                    {/* </Drawer> */}
                </Grid>
                <Grid container justifyContent="left" alignItems="stretch" item xs={10}>

                    <Box sx={{ flex: 1, p: 2 }}>
                        <Outlet />
                    </Box>
                </Grid>
            </Grid>
            {/* </Box> */}
        </ThemeProvider>
    );
}




