import PropTypes from 'prop-types';
import { Box, Button, ListItem } from '@mui/material';
import { NavLink, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export const NavItem = (props) => {
    const { href, title, ...others } = props;
    const isActive = useLocation().pathname.includes(href) ? true : false;

    const { isAuthenticated } = useAuth0();

    // Вернуть null, если пользователь не аутентифицирован
    if (!isAuthenticated) return null;

    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                mb: 1.5,
                py: 0,
                px: 2
            }}
            {...others}
        >
            <Box sx={{ flex: 1 }}>

                <NavLink
                    to={href}
                >

                    <Button
                        disableRipple
                        sx={{
                            backgroundColor: isActive && 'rgba(255,255,255, 0.08)',
                            borderRadius: 1,
                            color: isActive ? 'secondary.main' : 'neutral.300',
                            fontWeight: isActive && 'fontWeightBold',
                            justifyContent: 'flex-start',
                            px: 3,
                            textAlign: 'center',
                            textTransform: 'none',
                            width: '100%',
                            '& .MuiButton-startIcon': {
                                color: isActive ? 'secondary.main' : 'neutral.400'
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(255,255,255, 0.08)'
                            }

                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            {title}
                        </Box>
                    </Button>
                </NavLink>
            </Box>
        </ListItem>
    );
};

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.node,
    title: PropTypes.string
};
