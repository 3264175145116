import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useRecoilValue } from 'recoil';
import { series_selector, series_state } from '../atoms/series/series';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import { useSeries } from '../hooks/series/series';
import DeleteSeries from '../Series/Series/DeleteSeries';
import { CircularProgress } from '@mui/material';






export default function Series() {
    const {
        series,
    } = useSeries(series_state);
    const series_keys = useRecoilValue(series_selector);

    return (
        <>
            <h1>Series</h1>
            {Object.keys(series).length === 0 ? <CircularProgress /> : ''}

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" >
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Courses</TableCell>
                            <TableCell align="right">index</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {series_keys.map((key) => (
                            <TableRow
                                key={series[key].title}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Link to={`/series/current_series/${key}`}>

                                        {series[key].title}
                                    </Link>

                                </TableCell>
                                <TableCell align="right">{series[key].courses.length}</TableCell>
                                <TableCell align="right">{series[key].index}</TableCell>
                                <TableCell>
                                    <DeleteSeries series_key={key}></DeleteSeries>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <Link to={`/series/new_series`}>

                <Button variant="contained">
                    Add new series
                </Button>
            </Link>


        </>


    );
}
