import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import RecoilNexus from "recoil-nexus";
import { RecoilRoot } from 'recoil';
import { Auth0Provider } from "@auth0/auth0-react"; // импортируйте Auth0Provider
import App from './App';
import reportWebVitals from './reportWebVitals';
import ws from './Websockets/Instance'
import './index.css'

console.log(process.env)
if (module.hot) {
  module.hot.accept()
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-8f1td2m8rdlrqauv.eu.auth0.com"
      clientId="1lIpMPoVjlNB4l83o2LTmeIiNcQsOHCp"
      redirectUri={window.location.origin}
    >
      <RecoilRoot>
        <RecoilNexus></RecoilNexus>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </RecoilRoot>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
