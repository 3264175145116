import React from 'react';
import { useRecoilValue } from 'recoil';
import { series_state, series_indexes_selector, series_selector } from '../atoms/series/series';
import { useParams } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { new_series } from '../atoms/series/new_series';
import { useNewSeries } from '../hooks/series/new_series';
import ws from '../Websockets/Instance';
import DeleteIcon from '@mui/icons-material/Delete';
import { RotateLeft } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSeries } from '../hooks/series/series';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function NewSeries() {
    const series = useRecoilValue(series_state);
    let indexes = useRecoilValue(series_indexes_selector)
    let series_keys = useRecoilValue(series_selector)
    // let new_series_state = useRecoilValue(new_series)
    const { new_series_state,
        reset_state_and_add_new_index,
        add_course,
        delete_course,
        add_question_to_course,
        delete_question_by_course,
        change_vimeo_link_by_course,
        change_title_by_course,
        change_series_title,
        change_series_index,
        change_answer_by_course_by_question,
        change_question_by_course_by_question,
        change_correct_answer_by_course_by_question
    } = useNewSeries(new_series);
    // const add_course = () => add_course_hook();
    const {
        add_new_series
    } = useSeries(series_state);
    let { title, index, courses } = new_series_state

    let series_index = 1
    let answers = [0, 1, 2, 3]
    series_index = series_keys.length + 1

    const [current_course, setCurrentCourse] = React.useState(0)
    const [current_question, setCurrentQuestion] = React.useState(0)
    const [open, setOpen] = React.useState(false);
    const handleOpen = (course_index, question_index) => {
        setCurrentCourse(course_index)
        setCurrentQuestion(question_index)
        setOpen(true)
    }
    const delete_question = () => {
        setOpen(false)
        delete_question_by_course(current_course, current_question)
    }
    /*
        status = 0 -- init
        status = 1 -- loading
        status = 2 -- success
        status = 3 -- error
    */
    const [status, setStatus] = React.useState(0);

    const handle_save = async () => {
        try {
            setStatus(1)
            let response = await ws.request({ route: 'series/new_series', payload: { new_series_state } })
            let { payload } = response
            let { success } = payload
            if (success) {
                setStatus(2)
                add_new_series({ ...new_series_state })
            } else {
                setStatus(3)
            }
        } catch (error) {
            setStatus(3)
            console.log(error)
        }
    }
    let render_answers = false
    let question_value = ''
    let correct_answer = 0
    if (new_series_state.courses[current_course]) {
        if (new_series_state.courses[current_course].book.quiestions[current_question]) {
            render_answers = true
            question_value = new_series_state.courses[current_course].book.quiestions[current_question].quiestion
            correct_answer = new_series_state.courses[current_course].book.quiestions[current_question].correct_answer
        }
    }
    const handleClose = () => setOpen(false);
    return (
        <>
            <h1>New Series<br /></h1>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2>Questions</h2>
                    <Grid container spacing={0} rowSpacing={0} alignItems="stretch" justifyContent="left" >
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ flex: 1 }}>
                                    <p><b>
                                        Question
                                    </b>
                                        <Button onClick={delete_question}>
                                            <DeleteIcon />
                                        </Button>
                                    </p>
                                    <TextField value={question_value} onChange={({ target: { value } }) => { change_question_by_course_by_question(current_course, current_question, value) }} required></TextField>
                                </Box>

                                <Box sx={{ flex: 1 }}>
                                    <p><b>
                                        Correct answer
                                    </b>
                                    </p>
                                    <Select
                                        value={correct_answer}
                                        onChange={({ target: { value } }) => { change_correct_answer_by_course_by_question(current_course, current_question, value) }}
                                        required
                                    >
                                        {answers.map((answer, answer_index) => (
                                            <MenuItem key={answer_index} value={answer_index}>{answer_index + 1}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </Box>
                        </Grid>
                        {render_answers ? new_series_state.courses[current_course].book.quiestions[current_question].answers.map((answer, answer_index) => <Grid item xs={12}>
                            <Box key={answer_index} sx={{ paddingTop: 2 }}>
                                <p>
                                    <b>
                                        Answer {answer_index + 1}
                                    </b>
                                </p>
                                <TextField value={answer} onChange={({ target: { value } }) => { change_answer_by_course_by_question(current_course, current_question, answer_index, value) }} required></TextField>
                            </Box>
                        </Grid>) : ''}

                    </Grid>
                </Box>
            </Modal>
            <Box component={Paper} sx={{ p: 1 }}>
                <Grid container spacing={0} rowSpacing={0} alignItems="stretch" justifyContent="left" >

                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flex: 1 }}>
                                <p><b>index</b></p>
                                <Select
                                    value={index}
                                    required
                                    onChange={({ target: { value } }) => { change_series_index(value) }}
                                >
                                    {series_keys.map((key) => (
                                        <MenuItem key={key} value={indexes[key]}>{indexes[key]}</MenuItem>
                                    ))}
                                    <MenuItem key={index} value={index}>{index}</MenuItem>
                                </Select>

                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <p><b>Series title</b></p>
                                <TextField required value={title} onChange={({ target: { value } }) => { change_series_title(value) }} />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <p><b>Reset fields </b></p>

                                <Button onClick={() => {
                                    if (status === 2) {
                                        let index_value = series_keys.length + 1
                                        setStatus(0)
                                        reset_state_and_add_new_index(index_value)
                                    }
                                }}>
                                    <RotateLeft titleAccess='Reset Fileds for New Series, if your prev series added succesfully' />
                                </Button>
                            </Box>

                        </Box>
                    </Grid>
                    <Grid item xs={12}>

                        <Box sx={{ paddingTop: 4 }}>
                            <h3>Courses</h3>
                            {courses.map((course, index) => (
                                <div key={index}>
                                    <Grid justifyContent="left" alignItems="stretch" item xs={12}>

                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ flex: 1 }}>
                                                <p>
                                                    <b>
                                                        Course {index + 1}
                                                    </b>
                                                    <Button onClick={() => { delete_course(index) }}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </p>
                                                <TextField value={course.title} onChange={({ target: { value } }) => { change_title_by_course(index, value) }} required />

                                            </Box>
                                            <Box sx={{ flex: 1 }}>
                                                <p>
                                                    <b>
                                                        Vimeo link
                                                    </b>
                                                </p>
                                                <TextField value={course.book.video} onChange={({ target: { value } }) => { change_vimeo_link_by_course(index, value) }} required />
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid justifyContent="left" alignItems="stretch" item xs={12}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ flex: 1 }}>
                                                <p><b>Questions</b></p>
                                                <Stack spacing={2} direction="row">
                                                    {course.book.quiestions.map((question, question_index) => {
                                                        return (
                                                            <Button key={question_index} variant={true ? 'contained' : 'outlined'} onClick={() => { handleOpen(index, question_index) }}>{question_index + 1}</Button>
                                                        )
                                                    })}
                                                    {new_series_state.courses[index].book.quiestions.length < 5 ? <Button variant="contained" onClick={({ target: { value } }) => { add_question_to_course(index) }}>
                                                        Add question
                                                    </Button> : ''}

                                                </Stack>

                                                <span>Click to edit</span>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <br />
                                    <Divider></Divider>
                                </div>
                            ))}
                            <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                                {new_series_state.courses.length < 5 ? <Button variant="contained" onClick={add_course}>
                                    Add new Course
                                </Button> : ''}

                            </Box>
                            {status === 1 ? <CircularProgress /> : ''}

                            {status === 0 ? <Button variant="contained" onClick={handle_save}>
                                Save
                            </Button> : ''}
                            {status === 2 ? <b>Success</b> : ''}
                            {status === 3 ? <><Button variant="contained" onClick={handle_save}>Save</Button><b>Error</b></> : ''}

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>


    );
}
