import { atom, selector } from 'recoil';

export const series_state = atom({
    key: 'series',
    default: {},
});

export const series_selector = selector({
    key: 'series_selector',
    get: ({ get }) => {
        let state = get(series_state)
        let series_state_keys = Object.keys(state)
        return series_state_keys || []
    },
});

export const series_indexes_selector = selector({
    key: 'series_indexes_selector',
    get: ({ get }) => {
        let state = get(series_state)
        let series_state_keys = Object.keys(state) || []
        let indexes = {}
        for (let key of series_state_keys) {
            indexes[key] = state[key].index
        }
        return indexes
    },
})

export const copy_series = atom({
    key: 'copy_series',
    default: ({
        hi: {
            title: 'hi',
            index: 0,
            courses: [123, 13]
        }
    }),
})
